////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(253, 241, 184);
}
.bg-secondaryColor {
  background-color: rgb(158, 132, 72);
}
.bg-thirdColor {
  background-color: rgb(60, 89, 157);
}
.bg-fourthColor {
  background-color: rgb(158, 132, 72);
}
.bg-fifthColor {
  background-color: rgba(158, 132, 72, 0.5);
}
.bg-sixthColor {
  background-color: rgb(204, 188, 162);
}
.bg-seventhColor {
  background-color: rgb(188, 189, 160);
}
